import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { setCookie } from "nookies";
import Link from "next/link";
import { authGetRequest } from "../../../utils/authGetRequest";

const SearchOverlay = ({ isOpen, onClose }) => {
  const [authToken, setAuthToken] = useState(null);
  const [teamPlayerData, setTeamPlayerData] = useState([]); // [team1, team2, player1, player2, ...
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState({ teams: [], players: [] });
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    setAuthToken(document.cookie.split("token_access=").pop().split(";").shift());
  }, []);
  useEffect(() => {
    setSearchText("");
    setSearchResults({ teams: [], players: [] });
  }, [isOpen]);

  useEffect(() => {
    if (searchText.length > 2) {
      const teams = teamPlayerData.teams.filter((item) => item.team.toLowerCase().includes(searchText.toLowerCase()));
      const players = teamPlayerData.players.filter((item) =>
        item.player.toLowerCase().includes(searchText.toLowerCase())
      );
      setSearchResults({ teams, players });
      // const filteredData = {};
      // Object.keys(teamPlayerData).forEach((it) => {
      //   filteredData[it].players = teamPlayerData[it].players.filter((player) =>
      //     player.player.toLowerCase().includes(searchText.toLowerCase())
      //   );
      // });
      // Object.keys(teamPlayerData).forEach((it) => {
      //   filteredData[it].teams = teamPlayerData[it].teams.filter((team) =>
      //     team.toLowerCase().includes(searchText.toLowerCase())
      //   );
      // });
      // setSearchResults(filteredData);
    }
  }, [searchText, teamPlayerData]);

  useEffect(() => {
    if (authToken) {
      authGetRequest(`/api/search`, authToken)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          const data = response.data;
          const flattenedData = {
            players: [],
            teams: [],
          };
          Object.keys(data).forEach((it) => {
            const tempPlayers = data[it].players.map((player) => {
              return {
                player: player.player,
                team: player.team,
                league: it === "college_mens" ? "ncaa" : it,
              };
            });
            flattenedData.players.push(...tempPlayers);
          });
          Object.keys(data).forEach((it) => {
            const tempTeams = data[it].teams.map((team) => {
              return { team: team, league: it === "college_mens" ? "ncaa" : it };
            });
            flattenedData.teams.push(...tempTeams);
          });
          setTeamPlayerData(flattenedData);
        })
        .catch(async (err) => {
          if (err.message.includes("401")) {
            try {
              const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/refresh`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
                body: authToken,
              })
                .then((res) => res.json())
                .then((response) => {
                  setCookie("token_access", response.access_token);
                  setAuthToken(response.access_token);
                });
            } catch (error) {
              console.log(error);
            }
          }
          console.log("Request failed :(");
        })
        .finally(() => {});
    }
  }, [authToken]);
  return (
    <div className={`${styles.backdrop} ${isOpen ? styles.show : ""}`}>
      <div className={`w-100 ${styles.overlay} ${isOpen ? styles.show : ""}`}>
        <div className="d-flex gap-1">
          <input
            value={searchText}
            onChange={handleSearch}
            type="text"
            placeholder="Search Team/Player"
            className="input outline"
          />
          <button className={"btn btn-orange p-10-15"} onClick={onClose}>
            Close
          </button>
        </div>
        <div className="search-result-container w-100 pb-5">
          <div className="row">
            <div className="col-md-4 col-12 offset-md-1 pb-4">
              {searchResults.teams.length > 0 && (
                <>
                  <h2 className="color-orange text-center">Teams</h2>
                  {searchResults.teams.map((item, index) => (
                    <Link
                      href={`/team/${item.league}/2024/${item.team}`}
                      key={item.team + item.league}
                      className={`${styles.searchResults} text-white is-link d-block`}
                    >
                      <h3 className="text-white is-link"> {item.team}</h3>
                      <sup> {item.league}</sup>
                    </Link>
                  ))}
                </>
              )}
              {searchResults.teams.length == 0 && searchResults.players.length > 0 && searchText.length > 2 && (
                <>
                  <h2 className="color-orange text-center">Teams</h2>
                  <div className="text-center">No Teams found</div>
                </>
              )}
            </div>
            <div className="col-md-4 col-12 offset-md-2">
              {searchResults.players.length > 0 && (
                <>
                  <h2 className="color-orange text-center">Players</h2>
                  {searchResults.players.map((item, index) => (
                    <Link
                      href={`/${item.league}/player-props/${item.team}/${item.player}`}
                      key={item.player + item.team + item.league}
                      className={`${styles.searchResults} text-white is-link d-block`}
                    >
                      <h3 className="text-white is-link"> {item.player}</h3>
                      <sup>
                        {item.team} | {item.league}
                      </sup>
                    </Link>
                  ))}
                </>
              )}
              {searchResults.teams.length > 0 && searchResults.players.length == 0 && searchText.length > 2 && (
                <>
                  <h2 className="color-orange text-center">Players</h2>
                  <div className="text-center">No players found</div>
                </>
              )}
            </div>
          </div>
          {searchResults.teams.length === 0 && searchResults.players.length === 0 && searchText.length > 2 && (
            <div className={`${styles.searchResults} col-12 col-md-4 offset-md-4`}>No results found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;
